import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // 메인 페이지
  {
    // 기본 레이아웃
    path: "/",
    name: "defaultLayout",
    component: () => import("@/layout/DefaultLayout.vue"),
    children: [
      // /로 접속하면 /about으로 리다이렉트됨
      {
        path: "/",
        redirect: "/about",
        component: () => import("@/components/MainComponent.vue"),
      },
      // 소개
      {
        path: "/about",
        component: () => import("@/components/MainComponent.vue"),
      },
      // 다운로드
      {
        path: "/download",
        component: () => import("@/components/DownloadComponent.vue"),
      },

      // 공지사항
      {
        path: "/notice",
        component: () => import("@/components/MainComponent.vue"),
      },
      // 문의하기
      {
        path: "/inquiry",
        component: () => import("@/views/InquiryView.vue"),
        children: [
          {
            path: "/inquiry/form",
            component: () => import("@/components/inquiry/InquiryForm.vue"),
          },
          {
            path: "/inquiry/complete",
            component: () => import("@/components/inquiry/InquiryComplete.vue"),
          },
        ],
      },
      // FAQ
      {
        path: "/faq",
        component: () => import("@/views/FAQView.vue"),
      },
    ],
  },
  // 404 에러 페이지
  {
    path: "*",
    redirect: "/404",
  },
  {
    path: "/404",
    component: () => import("@/views/404View.vue"),
  },
  // 라이선스
  {
    path: "/license",
    component: () => import("@/views/LicenseView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,

  // 스크롤 초기화
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

export default router;
