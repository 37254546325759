import axios from "axios";

function createInstance() {
  return axios.create({
    baseURL: `/api`,
    headers: {
      // 'Content-type': 'application/json',
      'Cross-Origin-Embedder-Policy': 'require-corp',
      'Cross-Origin-Opener-Policy': 'same-origin',
      'Cross-Origin-Resource-Policy': 'cross-origin'
    },
  });
}

export const instance = createInstance();
