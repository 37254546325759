import Vue from 'vue';
import Vuex from 'vuex';
import {
  getAuthFromCookie,
  getUserFromCookie,
  saveAuthToCookie,
  saveUserToCookie,
} from "@/utils/cookies";
import { loginUser } from "@/api/auth";


Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		id: getUserFromCookie() || '',
		token: getAuthFromCookie() || '',
	},
	getters: {

	},
	mutations: {
		setUserId(state, id) {
      state.id = id;
    },
    clearUserId(state) {
      state.id = "";
    },
    setToken(state, token) {
      state.token = token;
    },
    clearToken(state) {
      state.token = "";
    },
	},
	actions: {
		// * 수정하기
		async LOGIN({ commit }, userData) {
      const { data } = await loginUser(userData);
      console.log(data.token);
      commit("setToken", data.token);
      commit("setUserId", data.id);
      saveAuthToCookie(data.token);
      saveUserToCookie(data.id);
      return data;
    },
	},
});
